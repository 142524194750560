function pendo(p, e, n, d, o) {
  const apiKey = "81eaffc2-4743-418d-52bf-28c8b2276c3e";
  let v, w, x, y, z;
  o = p[d] = p[d] || {};
  o._q = o._q || [];
  v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
  for (w = 0, x = v.length; w < x; ++w)
    (function (m) {
      o[m] =
        o[m] ||
        function () {
          o._q[m === v[0] ? "unshift" : "push"](
            [m].concat([].slice.call(arguments, 0))
          );
        };
    })(v[w]);
  y = e.createElement(n);
  y.async = !0;
  y.src = "https://cdn.eu.pendo.io/agent/static/" + apiKey + "/pendo.js";
  z = e.getElementsByTagName(n)[0];
  z.parentNode.insertBefore(y, z);
}
export const setupPendo = () => {
  pendo(window, document, "script", "pendo");
};
